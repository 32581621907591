<template>
  <narrow-layout>
    <div class="flex space-x-2">
      <div class="w-2/3">
        <card class="flex flex-col">
          <p class="pb-2 font-display text-xl font-black">Previous Releases</p>
          <div
            v-if="loading"
            class="flex h-full flex-col items-center justify-center"
          >
            <base-spinner class="text-4xl" />
          </div>
          <div v-if="!loading" class="mb-auto flex flex-col divide-y">
            <div
              v-for="release in releases.data"
              :key="release.id"
              class="flex justify-between py-3 hover:bg-gray-100"
            >
              <div class="w-1/3 font-semibold">
                {{ release.version }}
              </div>
              <div class="w-1/3">
                {{ release.message }}
              </div>
              <div class="w-1/3 text-right italic">
                {{ format(new Date(release.updated_at), 'yyyy-MM-dd HH:mm') }}
              </div>
            </div>
          </div>
          <div
            v-if="releases.data && releases.data.length && !loading"
            class="mt-4 flex w-full items-baseline justify-between"
          >
            <pagination-buttons
              :pagination-list="releases"
              :loading="loading"
              @previous="fetch(releases.prev_page_url)"
              @next="fetch(releases.next_page_url)"
            />
            <div>
              Viewing {{ releases.data.length }} of {{ releases.total }} entries
            </div>
          </div>
        </card>
      </div>
      <div class="w-1/3">
        <card class="w-full">
          <p class="font-display text-xl font-black">Create a Release</p>
          <form @submit.prevent="upgradeVersion">
            <div class="flex-1 py-4">
              <custom-select
                id="type"
                label-size="sm"
                size="sm"
                label-attr="name"
                value-attr="name"
                name="type"
                :options="types"
                placeholder="Select a type"
                :value="type"
                @change="type = $event"
              />
            </div>
            <div>
              <textarea-input
                id="message"
                v-model="message"
                name="message"
                class="text-sm"
                placeholder="Write message"
              />
            </div>
            <base-button
              color="secondary"
              class="mt-4 w-full"
              :loading="buttonLoading"
            >
              Update
            </base-button>
          </form>
        </card>
      </div>
    </div>
  </narrow-layout>
</template>

<script>
import { format } from 'date-fns';
import PaginationButtons from '@/UI/PaginationButtons';
import { VersionApi } from '@/api';

export default {
  components: {
    PaginationButtons,
  },
  async fetch(pageUrl = null) {
    this.loading = true;
    this.setTicketList([]);
    await this.fetchTickets(pageUrl)
      .then(res => {
        this.setTicketList(res.data);
      })
      .catch(e => {
        console.log({ e });
      })
      .finally(() => {
        this.loading = false;
      });
  },
  data() {
    return {
      types: [{ name: 'Patch' }, { name: 'Minor' }, { name: 'Major' }],
      type: '',
      message: '',
      loading: false,
      releases: [],
      format,
      buttonLoading: false,
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch(pageUrl = null) {
      this.loading = true;
      VersionApi.getAllVersions(pageUrl)
        .then(res => {
          this.releases = res;
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.loading = false;
        });
    },
    upgradeVersion() {
      this.buttonLoading = true;
      VersionApi.upgradeVersion(this.type.toLowerCase(), this.message)
        .then(res => {
          this.type = '';
          this.message = '';
          this.releases.data.unshift(res.version);
          this.releases.total += 1;
        })
        .catch(e => console.log(e))
        .finally(() => {
          this.buttonLoading = false;
        });
    },
  },
};
</script>
