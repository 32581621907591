var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("narrow-layout", [
    _c("div", { staticClass: "flex space-x-2" }, [
      _c(
        "div",
        { staticClass: "w-2/3" },
        [
          _c("card", { staticClass: "flex flex-col" }, [
            _c("p", { staticClass: "pb-2 font-display text-xl font-black" }, [
              _vm._v("Previous Releases"),
            ]),
            _vm.loading
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex h-full flex-col items-center justify-center",
                  },
                  [_c("base-spinner", { staticClass: "text-4xl" })],
                  1
                )
              : _vm._e(),
            !_vm.loading
              ? _c(
                  "div",
                  { staticClass: "mb-auto flex flex-col divide-y" },
                  _vm._l(_vm.releases.data, function (release) {
                    return _c(
                      "div",
                      {
                        key: release.id,
                        staticClass:
                          "flex justify-between py-3 hover:bg-gray-100",
                      },
                      [
                        _c("div", { staticClass: "w-1/3 font-semibold" }, [
                          _vm._v(" " + _vm._s(release.version) + " "),
                        ]),
                        _c("div", { staticClass: "w-1/3" }, [
                          _vm._v(" " + _vm._s(release.message) + " "),
                        ]),
                        _c("div", { staticClass: "w-1/3 text-right italic" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.format(
                                  new Date(release.updated_at),
                                  "yyyy-MM-dd HH:mm"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.releases.data && _vm.releases.data.length && !_vm.loading
              ? _c(
                  "div",
                  {
                    staticClass:
                      "mt-4 flex w-full items-baseline justify-between",
                  },
                  [
                    _c("pagination-buttons", {
                      attrs: {
                        "pagination-list": _vm.releases,
                        loading: _vm.loading,
                      },
                      on: {
                        previous: function ($event) {
                          return _vm.fetch(_vm.releases.prev_page_url)
                        },
                        next: function ($event) {
                          return _vm.fetch(_vm.releases.next_page_url)
                        },
                      },
                    }),
                    _c("div", [
                      _vm._v(
                        " Viewing " +
                          _vm._s(_vm.releases.data.length) +
                          " of " +
                          _vm._s(_vm.releases.total) +
                          " entries "
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "w-1/3" },
        [
          _c("card", { staticClass: "w-full" }, [
            _c("p", { staticClass: "font-display text-xl font-black" }, [
              _vm._v("Create a Release"),
            ]),
            _c(
              "form",
              {
                on: {
                  submit: function ($event) {
                    $event.preventDefault()
                    return _vm.upgradeVersion.apply(null, arguments)
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "flex-1 py-4" },
                  [
                    _c("custom-select", {
                      attrs: {
                        id: "type",
                        "label-size": "sm",
                        size: "sm",
                        "label-attr": "name",
                        "value-attr": "name",
                        name: "type",
                        options: _vm.types,
                        placeholder: "Select a type",
                        value: _vm.type,
                      },
                      on: {
                        change: function ($event) {
                          _vm.type = $event
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("textarea-input", {
                      staticClass: "text-sm",
                      attrs: {
                        id: "message",
                        name: "message",
                        placeholder: "Write message",
                      },
                      model: {
                        value: _vm.message,
                        callback: function ($$v) {
                          _vm.message = $$v
                        },
                        expression: "message",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "base-button",
                  {
                    staticClass: "mt-4 w-full",
                    attrs: { color: "secondary", loading: _vm.buttonLoading },
                  },
                  [_vm._v(" Update ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }